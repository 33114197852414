<template>
  <v-card class="pa-md-10 ma-md-5 ma-sm-0 pa-sm-0" outlined>
    <v-card-text class="pa-0">
      <v-container class="pa-0">
        <v-row>
          <v-col
            ><v-card outlined style="border: none">
              <v-card-text>
                <div>
                  En nous basant sur votre adresse e-mail, votre entreprise
                  semble déjà exister.
                </div>
                <div>
                  Vous pouvez prendre contact avec
                  <a :href="`mailto:${personneContact.email}`">{{
                    personneContact.email
                  }}</a>
                  afin de vous ajouter comme personne de contact.
                </div>
              </v-card-text>
            </v-card></v-col
          >
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["personneContact"],
  data: () => ({}),
};
</script>
