<template>
  <v-card class="pa-md-10 ma-md-5 ma-sm-0 pa-sm-0" outlined>
    <v-card-text class="pa-0">
      <v-container class="pa-0">
        <v-row>
          <v-col>
            <v-card outlined style="border: none">
              <v-card-text>
                <v-form v-model="valid">
                  <v-text-field
                    outlined
                    dense
                    label="E-mail *"
                    :value="personneContact.email.toLowerCase()"
                    @input="
                      (value) => {
                        personneContact.email = value;
                      }
                    "
                    :rules="[rules.required, rules.email]"></v-text-field>
                  <v-select
                    outlined
                    dense
                    label="Civilité *"
                    :items="civilites"
                    item-text="nom"
                    :item-value="(item) => item"
                    v-model="personneContact.civilite"
                    :rules="[rules.required]"></v-select>
                  <v-text-field
                    outlined
                    dense
                    label="Nom *"
                    v-model="personneContact.nom"
                    :rules="[rules.required]"></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    label="Prénom *"
                    v-model="personneContact.prenom"
                    :rules="[rules.required]"></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    label="Fonction"
                    v-model="personneContact.fonction"></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    label="Téléphone"
                    v-model="personneContact.telephone"></v-text-field>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="createPersonneContact(personneContact)"
        :loading="updating"
        outlined
        :disabled="!valid"
        >Créer</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { parseISO, format } from "date-fns";
import axios from "axios";

export default {
  data: () => ({
    timeout: null,
    entreprises: [{ presentation: { html: "" } }],
    parseISO,
    format,
    updating: false,
    personneContact: { email: "" },
    types: ["Problématique EE", "Problématique IM"],
    entreprise: undefined,
    civilites: undefined,
    valid: false,
    rules: {
      required: (v) => !!v || "Ce champ est requis.",
      email: (value) => {
        if (value) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Adresse e-mail non valide.";
        }
        return true;
      },
    },
  }),
  async mounted() {
    try {
      const {
        data: { civilites },
      } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/salutations`,
      });
      this.civilites = civilites;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async createPersonneContact(personneContact) {
      this.updating = true;
      try {
        await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}/contacts`,
          data: {
            variables: {
              ...personneContact,
              civiliteId: personneContact.civilite
                ? personneContact.civilite.id
                : null,
            },
          },
        });
        this.$router.push(`/companies/${this.$route.params.id}/contacts`);
      } catch (error) {
        console.log(error);
      } finally {
        this.updating = false;
      }
    },
    lowercase() {
      this.personneContact.email = this.personneContact.email.toLowerCase();
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>

<style>
.editor > div {
  box-shadow: none !important;
  border-radius: 4px !important;
}

.editor > div > div > header {
  border-radius: 4px !important;
}

.editor {
  border: solid 1px #c8c8c8 !important;
  border-radius: 4px !important;
}
</style>
