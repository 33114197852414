<template>
  <div v-if="entreprise">
    <v-card class="ma-md-5 ma-sm-0" elevation="0">
      <v-card-text class="pa-0">
        <v-btn
          :to="`/companies/${$route.params.id}/subjects/create`"
          block
          outlined
          >Créer une problématique</v-btn
        ></v-card-text
      ></v-card
    >
    <v-card
      class="ma-md-5 ma-sm-0"
      outlined
      v-for="subject in subjects"
      :key="subject.id"
    >
      <v-system-bar
        color="green"
        dark
        v-if="subject.statut === 'accepte'"
        class="mb-5"
        >Acceptée</v-system-bar
      >
      <v-system-bar
        color="grey"
        dark
        v-if="subject.statut === 'en_cours'"
        class="mb-5"
        >En cours de traitement</v-system-bar
      >
      <v-system-bar
        color="grey"
        v-if="subject.statut === 'a_traiter' || !subject.statut"
        dark
        class="mb-5"
        >Soumise</v-system-bar
      >
      <v-system-bar
        color="red"
        dark
        v-if="subject.statut === 'refuse'"
        class="mb-5"
        >Refusée</v-system-bar
      >
      <v-card-text class="pa-0">
        <v-container class="pa-0">
          <v-row>
            <v-col
              ><v-card outlined style="border: none">
                <v-card-text>
                  <v-form disabled>
                    <v-select
                      outlined
                      dense
                      label="Type de problématique"
                      :items="types"
                      v-model="subject.selectedType"
                      disabled
                    ></v-select>
                    <v-select
                      outlined
                      dense
                      label="Domaine"
                      :items="domaines"
                      item-text="nom"
                      :item-value="(item) => item"
                      v-model="subject.domaine"
                    ></v-select>
                    <v-text-field
                      outlined
                      dense
                      label="Titre"
                      v-model="subject.titre"
                    ></v-text-field>
                    <v-select
                      outlined
                      dense
                      label="Personne de contact"
                      :items="
                        entreprise.personnesContact.map(
                          ({ id, nom, prenom, fonction }) => ({
                            id,
                            nom,
                            prenom,
                            fonction,
                          })
                        )
                      "
                      :item-text="
                        (item) =>
                          `${item.prenom} ${item.nom} (${item.fonction})`
                      "
                      :item-value="(item) => item"
                      v-model="subject.personneContact"
                    ></v-select>
                    <div class="subtitle-1">Description</div>
                    <tiptap-vuetify
                      disabled
                      ref="description"
                      placeholder="Description"
                      :extensions="extensions"
                      class="mb-10 editor"
                      v-model="subject.description.html"
                    />
                  </v-form>
                </v-card-text> </v-card
            ></v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <snackbar :snackbar="snackbar" />
  </div>
</template>

<script>
import { parseISO, format } from "date-fns";
import axios from "axios";
import { htmlToSlateAST } from "@graphcms/html-to-slate-ast";
import {
  TiptapVuetify,
  Bold,
  BulletList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";
import Snackbar from "../components/Snackbar.vue";

export default {
  data: () => ({
    timeout: null,
    parseISO,
    format,
    extensions: [ListItem, BulletList, Bold, HardBreak],
    updating: false,
    updatingId: "",
    types: ["Problématique EE", "Problématique IM"],
    snackbar: { visible: false, text: "", color: "" },
    entreprise: undefined,
    domaines: undefined,
    deletionDialog: false,
  }),
  computed: {
    subjects() {
      return this.entreprise.personnesContact
        .map((pc) => {
          const problematiques = pc.problematiques.map((problematique) => {
            problematique.selectedType = "Problématique EE";
            problematique.personneContact = {
              id: pc.id,
              nom: pc.nom,
              prenom: pc.prenom,
              fonction: pc.fonction,
            };
            return problematique;
          });
          return [...problematiques];
        })
        .reduce((x, y) => x.concat(y), []);
    },
  },
  components: { TiptapVuetify, Snackbar },
  async mounted() {
    try {
      const {
        data: { domaines },
      } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/domaines`,
      });
      this.domaines = domaines;
      const { data: entreprise } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}?type=subject`,
      });
      this.entreprise = entreprise;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    openDeletionDialog({ id }) {
      this.deletionDialog = id;
    },
    async deleteSubject(subject) {
      console.log(subject);
      this.updating = true;
      try {
        await axios({
          method: "delete",
          url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}/subjects/${subject.id}?type=${subject.selectedType}`,
        });
        this.entreprise.personnesContact = this.entreprise.personnesContact.map(
          (pc) => {
            pc.problematiques = pc.problematiques.filter(
              (p) => p.id !== subject.id
            );
            return pc;
          }
        );
        this.deletionDialog = "";
        this.snackbar = {
          visible: true,
          text: "Problématique supprimée",
          color: "success",
        };
      } catch (error) {
        this.snackbar = {
          visible: true,
          text: "Erreur de suppression.",
          color: "error",
        };
        console.log(error);
      } finally {
        this.updating = false;
      }
    },
    async saveSubject(subject) {
      this.updating = true;
      this.updatingId = subject.id;
      try {
        const children = await htmlToSlateAST(subject.description.html);
        await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}/subjects/${subject.id}`,
          data: {
            variables: {
              ...subject,
              domainId: subject.domaine ? subject.domaine.id : null,
              personneContactId: subject.personneContact
                ? subject.personneContact.id
                : null,
              description: {
                children,
              },
            },
          },
        });
        this.snackbar = {
          visible: true,
          text: "Problématique mise à jour.",
          color: "success",
        };
      } catch (error) {
        this.snackbar = {
          visible: true,
          text: "Erreur de mise à jour.",
          color: "error",
        };
        console.log(error);
      } finally {
        this.updating = false;
        this.updatingId = "";
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>

<style>
.editor > div {
  box-shadow: none !important;
  border-radius: 4px !important;
}
.editor > div > div > header {
  border-radius: 4px !important;
}
.editor {
  border: solid 1px #c8c8c8 !important;
  border-radius: 4px !important;
}
</style>
