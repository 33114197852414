<template>
  <v-card class="pa-md-10 ma-md-5 ma-sm-0 pa-sm-0" v-if="entreprise" outlined>
    <v-card-text class="pa-0">
      <v-container class="pa-0">
        <v-row>
          <v-col
            ><v-card outlined style="border: none">
              <v-card-text>
                <v-form v-model="valid" ref="form">
                  <v-text-field
                    outlined
                    dense
                    label="Nom de l'entreprise *"
                    v-model="entreprise.nom"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    label="Rue"
                    v-model="entreprise.rue"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    label="Adresse complémentaire"
                    v-model="entreprise.rue2"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    label="Code postal"
                    v-model.number="entreprise.codePostal"
                    type="number"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    label="Ville"
                    v-model="entreprise.ville"
                  ></v-text-field>
                  <v-select
                    outlined
                    dense
                    label="Canton *"
                    :items="this.cantons"
                    item-text="nom"
                    :item-value="(item) => item"
                    v-model="entreprise.canton"
                    :rules="[rules.required]"
                  ></v-select>
                  <v-text-field
                    outlined
                    dense
                    label="Pays"
                    v-model="entreprise.pays"
                  ></v-text-field>
                  <v-select
                    outlined
                    dense
                    label="Nombre d'employés *"
                    :items="this.nombresEmployes"
                    item-text="nombre"
                    :item-value="(item) => item"
                    v-model="entreprise.nombreEmployes"
                    :rules="[rules.required]"
                  ></v-select>
                  <div class="subtitle-1">Présentation de l'entreprise</div>
                  <tiptap-vuetify
                    ref="presentation"
                    placeholder="Présentation"
                    v-model="entreprise.presentation.html"
                    :extensions="extensions"
                    @input="validateTipTap"
                    class="mb-10 editor"
                  />
                </v-form>
              </v-card-text> </v-card
          ></v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="saveCompany"
        :loading="updating"
        outlined
        :disabled="!valid"
        >Mettre à jour</v-btn
      >
    </v-card-actions>
    <snackbar :snackbar="snackbar" />
  </v-card>
</template>

<script>
import { parseISO, format } from "date-fns";
import axios from "axios";
import { htmlToSlateAST } from "@graphcms/html-to-slate-ast";
import {
  TiptapVuetify,
  Bold,
  BulletList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";
import Snackbar from "../components/Snackbar.vue";

export default {
  data: () => ({
    timeout: null,
    companies: undefined,
    entreprise: undefined,
    slotId: undefined,
    companyId: undefined,
    parseISO,
    format,
    extensions: [ListItem, BulletList, Bold, HardBreak],
    updating: false,
    snackbar: { visible: false, text: "", color: "" },
    cantons: undefined,
    nombresEmployes: undefined,
    valid: false,
    rules: { required: (v) => !!v || "Ce champ est requis." },
  }),
  components: { TiptapVuetify, Snackbar },
  async mounted() {
    try {
      const {
        data: { cantons },
      } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/cantons`,
      });
      this.cantons = cantons;
      const {
        data: { nombresEmployes },
      } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/employees-count`,
      });
      this.nombresEmployes = nombresEmployes;
      const { data: entreprise } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}?type=profile`,
      });
      this.entreprise = entreprise;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    validateTipTap() {
      this.valid = false;
      this.tiptapText = this.htmlToText(this.entreprise.presentation.html);
      this.tiptapIsEmpty = false;
      if (this.tiptapText === "") {
        this.tiptapIsEmpty = true;
      }
      if (this.tiptapIsEmpty) this.tiptapIsValid = false;
      else this.tiptapIsValid = true;
      if (this.$refs.form.validate() && this.tiptapIsValid) {
        this.valid = true;
      }
    },
    htmlToText(html) {
      return html.replace(/<[^>]*>?/gm, "");
    },
    selectSlot(id) {
      this.slotId = id;
    },
    async saveCompany() {
      this.updating = true;
      try {
        const children = await htmlToSlateAST(
          this.entreprise.presentation.html
        );
        await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}`,
          data: {
            variables: {
              ...this.entreprise,
              presentation: {
                children,
              },
              cantonId: this.entreprise.canton
                ? this.entreprise.canton.id
                : null,
              nombreEmployesId: this.entreprise.nombreEmployes
                ? this.entreprise.nombreEmployes.id
                : null,
            },
          },
        });
        this.snackbar = {
          visible: true,
          text: "Entreprise mise à jour.",
          color: "success",
        };
      } catch (error) {
        this.snackbar = {
          visible: true,
          text: "Erreur de mise à jour.",
          color: "error",
        };
        console.log(error);
      } finally {
        this.updating = false;
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  watch: {
    valid() {
      this.validateTipTap();
    },
  },
};
</script>

<style>
.editor > div {
  box-shadow: none !important;
  border-radius: 4px !important;
}
.editor > div > div > header {
  border-radius: 4px !important;
}
.editor {
  border: solid 1px #c8c8c8 !important;
  border-radius: 4px !important;
}
</style>
