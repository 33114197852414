<template>
  <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
    {{ snackbar.text }}

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar.visible = false">
        Fermer
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: ["snackbar"],
};
</script>
