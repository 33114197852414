<template>
  <div></div>
</template>

<script>
export default {
  props: ["companyId"],

  mounted() {
    this.$router.push(`/companies/${this.companyId}`);
  },
};
</script>
