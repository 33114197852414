<template>
  <div v-if="entreprise && civilites">
    <v-card class="ma-md-5 ma-sm-0" elevation="0">
      <v-card-text class="pa-0">
        <v-btn
          :to="`/companies/${$route.params.id}/contacts/create`"
          block
          outlined
          >Créer une personne de contact</v-btn
        ></v-card-text
      ></v-card
    >
    <v-card
      class="pa-md-10 ma-md-5 ma-sm-0 pa-sm-0"
      outlined
      v-for="(personneContact, i) in entreprise.personnesContact"
      :key="personneContact.id"
    >
      <v-card-text class="pa-0">
        <v-container class="pa-0">
          <v-row>
            <v-col
              ><v-card outlined style="border: none">
                <v-card-text>
                  <v-form v-model="valid[i]">
                    <v-text-field
                      outlined
                      dense
                      label="E-mail"
                      v-model="personneContact.email"
                      :rules="[rules.required]"
                      disabled
                    ></v-text-field>
                    <v-select
                      outlined
                      dense
                      label="Civilité *"
                      :items="civilites"
                      item-text="nom"
                      :item-value="(item) => item"
                      v-model="personneContact.civilite"
                      :rules="[rules.required]"
                    ></v-select>
                    <v-text-field
                      outlined
                      dense
                      label="Nom *"
                      v-model="personneContact.nom"
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      outlined
                      dense
                      label="Prénom *"
                      v-model="personneContact.prenom"
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      outlined
                      dense
                      label="Fonction"
                      v-model="personneContact.fonction"
                    ></v-text-field>
                    <v-text-field
                      outlined
                      dense
                      label="Téléphone"
                      v-model="personneContact.telephone"
                    ></v-text-field>
                  </v-form>
                </v-card-text> </v-card
            ></v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="saveContact(personneContact)"
          :loading="updating && updatingId === personneContact.id"
          :disabled="updating || !valid[i]"
          outlined
          >Mettre à jour</v-btn
        >
      </v-card-actions>
    </v-card>
    <snackbar :snackbar="snackbar" />
  </div>
</template>

<script>
import { parseISO, format } from "date-fns";
import axios from "axios";
import Snackbar from "../components/Snackbar.vue";

export default {
  data: () => ({
    timeout: null,
    companies: undefined,
    entreprise: undefined,
    civilites: undefined,
    slotId: undefined,
    companyId: undefined,
    parseISO,
    format,
    updating: false,
    updatingId: "",
    snackbar: { visible: false, text: "", color: "" },
    valid: [],
    rules: {
      required: (v) => !!v || "Ce champ est requis.",
    },
  }),
  components: { Snackbar },
  async mounted() {
    try {
      const { data: entreprise } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}?type=contact`,
      });
      this.entreprise = entreprise;
      const {
        data: { civilites },
      } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/salutations`,
      });
      this.civilites = civilites;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async saveContact(personneContact) {
      this.updating = true;
      this.updatingId = personneContact.id;
      try {
        await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}/contacts/${personneContact.id}`,
          data: {
            variables: {
              ...personneContact,
              civiliteId: personneContact.civilite
                ? personneContact.civilite.id
                : null,
            },
          },
        });
        this.snackbar = {
          visible: true,
          text: "Contact mis à jour.",
          color: "success",
        };
      } catch (error) {
        console.log(error);
        this.snackbar = {
          visible: true,
          text: "Erreur de mise à jour.",
          color: "error",
        };
      } finally {
        this.updating = false;
        this.updatingId = "";
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>

<style scoped>
#map {
  top: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
}
</style>
