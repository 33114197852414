<template>
  <v-app>
    <v-app-bar app flat color="grey lighten-4">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-if="
          companyId &&
          ['CompanyCreate', 'CompanyExisting'].indexOf($route.name) === -1
        "
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <user-profile />
    </v-app-bar>
    <v-navigation-drawer
      app
      v-model="drawer"
      overlay-opacity="0"
      v-if="
        companyId &&
        ['CompanyCreate', 'CompanyExisting'].indexOf($route.name) === -1
      "
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar tile>
            <img
              src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>MEE 2022</v-list-item-title>
            <v-list-item-subtitle>Interface entreprise</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item :to="`/information`" exact>
          <v-list-item-icon>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Informations pratiques</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="`/companies/${this.companyId}`" exact>
          <v-list-item-icon>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Résumé</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="`/companies/${this.companyId}/profile`" exact>
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profil entreprise</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="`/companies/${this.companyId}/contacts`" exact>
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Personnes de contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="`/companies/${this.companyId}/subjects`" exact>
          <v-list-item-icon>
            <v-icon>mdi-book-open-page-variant-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Problématiques</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="ma-md-10 ma-sm-0">
      <div id="nav"></div>
      <router-view
        :companyId="companyId"
        :personneContactId="personneContactId"
        :setCompanyId="setCompanyId"
        :personneContact="personneContact"
        v-if="companyId"
      />
    </v-main>
  </v-app>
</template>

<script>
import UserProfile from "@/components/UserProfile";
import axios from "axios";

export default {
  name: "App",
  components: { UserProfile },
  async mounted() {
    try {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/user-context`,
      });
      this.companyId = data.companyId;
      this.personneContactId = data.personneContactId;
      this.personneContact = data.personneContact;
    } catch (error) {
      this.$router.push("/companies/create");
      console.log(error);
    }
  },
  data: () => ({
    fab: false,
    drawer: null,
    student: null,
    companyId: "",
  }),
  methods: {
    setCompanyId(id) {
      this.companyId = id;
    },
  },
};
</script>

<style>
.separator {
  display: inline-block;
}
</style>