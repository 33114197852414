<template>
  <v-card outlined v-if="student">
    <v-row class="my-1">
      <v-col>
        <v-btn text small plain @click="$router.back()" :ripple="false"
          ><v-icon small class="mr-2">mdi-arrow-left</v-icon>retour</v-btn
        ></v-col
      >
    </v-row>
    <v-divider block></v-divider>
    <v-container class="pa-0">
      <v-card-text class="pa-md-10 ma-md-5 ma-sm-0 pa-sm-0">
        <v-row class="text-center">
          <v-col
            ><v-avatar
              v-if="!student.picture"
              color="grey"
              size="86"
              v-text="shortName"
              class="white--text text-h5"
            ></v-avatar>
            <v-avatar v-else size="86" class="white--text text-h5"
              ><img :src="`${apiUri}/${student.picture}`" alt="student-picture"
            /></v-avatar>
            <div class="headline mt-4">
              {{ student.firstname }} {{ student.lastname }}
            </div>
            <div class="subtitle">{{ student.filiere }}</div>
          </v-col>
        </v-row>
        <v-row class="mt-0" justify="center">
          <v-col sm="4">
            <div v-if="student.cv" class="text-center">
              <a @click="downloadDocument(student.cv)" style="color: black">
                <v-icon x-large>mdi-file-outline</v-icon>
                <div>{{ student.cv.substr(22) }}</div>
              </a>
            </div>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col
            ><v-textarea
              outlined
              dense
              auto-grow
              label="Présentation"
              v-model="student.description"
              disabled
            ></v-textarea
          ></v-col>
        </v-row>
      </v-card-text>
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.visible = false">
            Fermer
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-card>
</template>
<script>
import axios from "axios";
import fileDownload from "js-file-download";

export default {
  name: "ProfileForm",
  data: () => ({
    student: undefined,
    snackbar: { visible: false, text: "", color: "" },
    apiUri: process.env.VUE_APP_API_URI,
  }),
  methods: {
    async downloadDocument(filename) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/files/${filename}`,
        responseType: "arraybuffer",
      });
      fileDownload(data, filename.substr(22));
    },
  },
  computed: {
    shortName: function () {
      return `${this.student.firstname.substr(
        0,
        1
      )}${this.student.lastname.substr(0, 1)}`;
    },
  },
  async mounted() {
    try {
      const { data: student } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/students/${this.$route.params.id}`,
      });
      this.student = student;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
