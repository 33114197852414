<template>
  <v-card class="pa-md-10 ma-md-5 ma-sm-0 pa-sm-0" v-if="entreprise" outlined>
    <v-card-text class="pa-0">
      <v-container class="pa-0">
        <v-row>
          <v-col
            ><v-card outlined style="border: none">
              <v-card-text>
                <v-form v-model="valid" ref="form">
                  <v-select
                    outlined
                    dense
                    label="Domaine *"
                    :items="domaines"
                    item-text="nom"
                    :item-value="(item) => item"
                    v-model="subject.domaine"
                    :rules="[rules.required]"
                  ></v-select>
                  <v-text-field
                    outlined
                    dense
                    label="Titre *"
                    v-model="subject.titre"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-select
                    outlined
                    dense
                    label="Personne de contact *"
                    :items="entreprise.personnesContact"
                    :item-text="
                      (item) =>
                        item.fonction
                          ? `${item.prenom} ${item.nom} (${item.fonction})`
                          : `${item.prenom} ${item.nom}`
                    "
                    :item-value="(item) => item"
                    v-model="subject.personneContact"
                    :rules="[rules.required]"
                  ></v-select>
                  <div class="subtitle-1">Description *</div>
                  <tiptap-vuetify
                    ref="description"
                    placeholder="Description"
                    :extensions="extensions"
                    class="mb-10 editor"
                    v-model="subject.description.html"
                    @input="validateTipTap"
                  />
                </v-form>
              </v-card-text> </v-card
          ></v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="createSubject" :loading="updating" :disabled="!valid"
        >Créer la problématique</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { parseISO, format } from "date-fns";
import axios from "axios";
import { htmlToSlateAST } from "@graphcms/html-to-slate-ast";

import {
  TiptapVuetify,
  Bold,
  BulletList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";

export default {
  data: () => ({
    timeout: null,
    entreprises: [{ presentation: { html: "" } }],
    parseISO,
    format,
    extensions: [ListItem, BulletList, Bold, HardBreak],
    updating: false,
    subject: { description: { html: "" } },
    entreprise: undefined,
    domaines: undefined,
    valid: false,
    rules: {
      required: (v) => !!v || "Ce champ est requis.",
    },
  }),
  components: { TiptapVuetify },
  async mounted() {
    try {
      const { data: entreprise } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}?type=subject-create`,
      });
      this.entreprise = entreprise;
      const {
        data: { domaines },
      } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/domaines`,
      });
      this.domaines = domaines;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    validateTipTap() {
      this.valid = false;
      this.tiptapText = this.htmlToText(this.subject.description.html);
      this.tiptapIsEmpty = false;
      if (this.tiptapText === "") {
        this.tiptapIsEmpty = true;
      }
      if (this.tiptapIsEmpty) this.tiptapIsValid = false;
      else this.tiptapIsValid = true;
      if (this.$refs.form.validate() && this.tiptapIsValid) {
        this.valid = true;
      }
    },
    htmlToText(html) {
      return html.replace(/<[^>]*>?/gm, "");
    },
    async createSubject() {
      this.updating = true;
      try {
        const children = await htmlToSlateAST(this.subject.description.html);
        await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}/subjects`,
          data: {
            variables: {
              ...this.subject,
              domainId: this.subject.domaine ? this.subject.domaine.id : null,
              personneContactId: this.subject.personneContact
                ? this.subject.personneContact.id
                : null,
              description: {
                children,
              },
            },
          },
        });
        this.$router.push(`/companies/${this.$route.params.id}/subjects`);
      } catch (error) {
        console.log(error);
      } finally {
        this.updating = false;
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  watch: {
    valid() {
      this.validateTipTap();
    },
  },
};
</script>

<style>
.editor > div {
  box-shadow: none !important;
  border-radius: 4px !important;
}
.editor > div > div > header {
  border-radius: 4px !important;
}
.editor {
  border: solid 1px #c8c8c8 !important;
  border-radius: 4px !important;
}
</style>
