import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import axios from "axios";
import router from "./router";

Vue.config.productionTip = false;

const tokenInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

Vue.use(VueKeyCloak, {
  init: {
    onLoad: 'login-required',
    checkLoginIframe: false
  },
  config: {
    realm: process.env.VUE_APP_KC_REALM,
    url: process.env.VUE_APP_KC_URL,
    clientId: process.env.VUE_APP_KC_CLIENT_ID,
  },
  onReady: async (keycloak) => {
    tokenInterceptor();
    try {
      new Vue({
        vuetify,
        router,
        render: (h) => h(App),
      }).$mount("#app");
    } catch (error) {
      console.log(error);
      keycloak.logout();
    }
  },
});
