import Vue from "vue";
import VueRouter from "vue-router";
import CompanyDetails from "../views/CompanyDetails.vue";
import CompanyProfile from "../views/CompanyProfile.vue";
import CompanySubjects from "../views/CompanySubjects.vue";
import CompanyContacts from "../views/CompanyContacts.vue";
import CompanyCreate from "../views/CompanyCreate.vue";
import CompanyExisting from "../views/CompanyExisting.vue";
import StudentDetails from "../views/StudentDetails.vue";
import SubjectCreate from "../views/SubjectCreate.vue";
import GetCompany from "../views/GetCompany.vue";
import ContactCreate from "../views/ContactCreate.vue";
import Information from "../views/Information.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/companies",
  },
  {
    path: "/companies",
    name: "GetCompany",
    component: GetCompany,
  },
  {
    path: "/companies/create",
    name: "CompanyCreate",
    component: CompanyCreate,
  },
  {
    path: "/companies/existing",
    name: "CompanyExisting",
    component: CompanyExisting,
  },
  {
    path: "/companies/:id",
    name: "CompanyDetails",
    component: CompanyDetails,
  },
  {
    path: "/companies/:id/profile",
    name: "CompanyProfile",
    component: CompanyProfile,
  },
  {
    path: "/companies/:id/subjects",
    name: "CompanySubjects",
    component: CompanySubjects,
  },
  {
    path: "/companies/:id/subjects/create",
    name: "SubjectCreate",
    component: SubjectCreate,
  },
  {
    path: "/companies/:id/contacts",
    name: "CompanyContacts",
    component: CompanyContacts,
  },
  {
    path: "/companies/:id/contacts/create",
    name: "ContactCreate",
    component: ContactCreate,
  },
  {
    path: "/students/:id",
    name: "StudentDetails",
    component: StudentDetails,
  },
  {
    path: "/information",
    name: "Information",
    component: Information,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
