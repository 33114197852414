<template>
  <v-card class="pa-md-10 ma-md-5 ma-sm-0 pa-sm-0" v-if="entreprise" outlined>
    <v-card-title class="text-center">{{ entreprise.nom }}</v-card-title>
    <v-card-text class="pa-0">
      <v-container class="pa-0">
        <v-row>
          <v-col
            ><v-card outlined style="border: none">
              <v-card-title class="subtitle-2"
                ><v-icon class="mr-3">mdi-information-outline</v-icon
                >Présentation</v-card-title
              >
              <v-card-text
                ><v-card outlined
                  ><v-card-text v-html="entreprise.presentation.html">
                  </v-card-text></v-card></v-card-text></v-card
          ></v-col>
        </v-row>
        <v-row v-if="entreprise.carte">
          <v-col
            ><v-card outlined style="border: none">
              <v-card-title class="subtitle-2"
                ><v-icon class="mr-3">mdi-map-marker-outline</v-icon
                >Adresse</v-card-title
              >
              <v-card-text>
                <v-card outlined>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <div id="map"></div>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <div>{{ entreprise.rue }}</div>
                          <div v-if="entreprise.rue2">
                            {{ entreprise.rue2 }}
                          </div>
                          <div>
                            {{ entreprise.codePostal }}
                            {{ entreprise.ville }}
                          </div>
                          <div v-if="entreprise.canton">
                            {{ entreprise.canton.nom }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card></v-col
          >
        </v-row>
        <v-row>
          <v-col
            ><v-card outlined style="border: none">
              <v-card-title class="subtitle-2"
                ><v-icon class="mr-3">mdi-account-tie</v-icon>Personnes de
                contact</v-card-title
              ><v-card-text>
                <v-card
                  outlined
                  v-for="personneContact in entreprise.personnesContact.filter(
                    (pc) => pc.email === this.$keycloak.tokenParsed.email
                  )"
                  :key="personneContact.id"
                  class="mt-3"
                  ><v-card-title
                    >{{ personneContact.prenom }}
                    {{ personneContact.nom }}</v-card-title
                  ><v-card-subtitle>
                    <div>{{ personneContact.fonction }}</div>
                    <div class="mt-3">
                      <div>
                        <a
                          :href="personneContact.teamschannel"
                          target="_blank"
                          >{{ personneContact.teamschannel }}</a
                        >
                      </div>
                    </div> </v-card-subtitle
                  ><v-divider /><v-card-text
                    ><div class="subtitle-2">Problématiques proposées</div>
                    <div
                      v-for="problematiqueEE in personneContact.problematiques"
                      :key="problematiqueEE.id"
                      class="mt-5"
                    >
                      <div class="font-weight-bold">
                        {{ problematiqueEE.titre }}
                        <v-chip x-small v-if="problematiqueEE.domaineEE">{{
                          problematiqueEE.domaineEE.nom
                        }}</v-chip>
                      </div>
                      <div v-html="problematiqueEE.description.html"></div>
                    </div> </v-card-text
                  ><v-divider />
                  <div>
                    <div class="pa-3" v-if="companies">
                      <div class="subtitle-2 mb-4 ml-1">Vos rendez-vous</div>
                      <div
                        v-for="slot in companies.find(
                          (c) => c.contactPersonId === personneContact.id
                        ).slots"
                        :key="slot.student.id"
                      >
                        <v-card
                          class="mt-3"
                          outlined
                          @click="$router.push(`/students/${slot.student._id}`)"
                        >
                          <v-card-title
                            >{{ slot.student.firstname }}
                            {{ slot.student.lastname }}
                            <span class="body-2 ml-2"
                              >({{ slot.student.filiere }})</span
                            ></v-card-title
                          ><v-card-subtitle
                            >{{ format(parseISO(slot.start), "HH:mm") }} -
                            {{
                              format(parseISO(slot.end), "HH:mm")
                            }}</v-card-subtitle
                          ></v-card
                        >
                      </div>
                    </div>
                  </div></v-card
                >
              </v-card-text>
            </v-card></v-col
          >
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import mapboxgl from "mapbox-gl";
import axios from "axios";
import { parseISO, format } from "date-fns";

export default {
  data: () => ({
    timeout: null,
    companies: undefined,
    entreprise: undefined,
    slotId: undefined,
    companyId: undefined,
    parseISO,
    format,
  }),
  methods: {
    selectSlot(id) {
      this.slotId = id;
    },
  },
  async mounted() {
    const { data: entreprise } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}?type=full`,
    });
    this.entreprise = entreprise;
    mapboxgl.accessToken =
      "pk.eyJ1IjoiY2xpZW5nbWUiLCJhIjoiY2t0aXJtaGppMTR5YTJ2am82ODBuOGM3OCJ9.xtCFAxrKOoIlrtT74Mspvg";
    this.timeout = setTimeout(async () => {
      if (this.entreprise.carte) {
        const map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: [
            this.entreprise.carte.longitude,
            this.entreprise.carte.latitude,
          ],
          zoom: 14,
          interactive: false,
        });

        new mapboxgl.Marker()
          .setLngLat([
            this.entreprise.carte.longitude,
            this.entreprise.carte.latitude,
          ])
          .addTo(map);
      }
      const companiesPromise = this.entreprise.personnesContact.map((pc) =>
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/admin/companies/${this.$route.params.id}/contacts/${pc.id}`,
        })
      );
      const companies = await Promise.all(companiesPromise);
      this.companies = companies.map((c) => c.data);
    }, 500);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>

<style scoped>
#map {
  top: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
}
</style>
